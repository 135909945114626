const { get } = require('jquery');

require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('./slick-carousel.css')
require('slick-carousel');

$slick_slider = $('.slick-carousel');

$slick_slider.each(function (index, element) {
  let carousel = $(element);
  // carousel events - start
  carousel.on('init', function (event, slick) {
    let carouselDom = this;
    let video = slickSlideHasVideo(carouselDom);
    if (video) {
      addEndedEventListener(video, carouselDom);
      setTimeout(function () {
        $(carouselDom).slick('slickPause');
        video.play();
      }, 0);
    }
  });
  carousel.on("beforeChange", function (event, slick) {
    let carouselDom = this;
    let video = slickSlideHasVideo(carouselDom);
    if (video) {
      video.pause();
      video.currentTime = 0;
      if (slick.paused) {
        $(carouselDom).slick('slickPlay');
      }
    }
  });
  carousel.on("afterChange", function (event, slick) {
    let carouselDom = this;
    let video = slickSlideHasVideo(carouselDom);
    if (video) {
      $(carouselDom).slick('slickPause');
      video.play();
      if (!video.classList.contains('hasEndedEventListener')) {
        addEndedEventListener(video, carouselDom);
      }
    }
  });
  // carousel events - end
  
  // carousel initialization
  carousel.slick();
});

function slickSlideHasVideo(carouselDom) {
  let currentSlide = carouselDom.querySelector('.slick-current');
  if (currentSlide) {
    let video = currentSlide.querySelector('video');
    return video;
  } else {
    return false;
  }
}
function addEndedEventListener(video, carouselDom) {
  video.addEventListener('ended', function () {
    this.classList.add('hasEndedEventListener');
    $(carouselDom).slick('slickPlay');
    $(carouselDom).slick('slickNext');
  });
}

$(window).on('resize', function () {
  if (!$slick_slider.hasClass('slick-initialized')) {
    return $slick_slider.slick();
  }
});